import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/config/store';

import { HashRouter as Router } from 'react-router-dom';
import App from './App';
import { docReady } from './utils/docReady';
import vh from './utils/vh';
import SizeHelper from './utils/SizeHelper';
import { BASE_PATH } from './Constants';

docReady.then(() => {
	// Some tooling to help with mobile browsers
	const ctx = document.documentElement;
	[
		vh,
		SizeHelper,
	].forEach(module => module.init(ctx));

	// Render the app
	const root = ReactDOM.createRoot(
		document.getElementById('root') as HTMLElement,
	);
	
	root.render(
		<Provider store={store}>
			<Router basename={BASE_PATH}>
				<App />
			</Router>
		</Provider>,
	);
});
