'format es6';
'use strict';

function onResize() {
	const vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const exp = {
	init() {
		window.addEventListener('resize', onResize);
		onResize();
	},
};

export default exp;