import { useCallback, useLayoutEffect, useRef } from 'react';

function useAnimationFrame(cb:(props?:{ time: number, delta: number }) => void) {
	const cbRef = useRef(null);
	const frame = useRef(null);
	const init = useRef(performance?.now?.());
	const last = useRef(performance?.now?.());

	cbRef.current = cb;

	const animate = useCallback((now) => {
		cbRef.current({
			time: (now - init.current) / 1000,
			delta: (now - last.current) / 1000,
		});
		last.current = now;
		frame.current = requestAnimationFrame(animate);
	}, []);

	useLayoutEffect(() => {
		frame.current = requestAnimationFrame(animate);
		return () => frame.current && cancelAnimationFrame(frame.current);
	}, [animate]);
};

export default useAnimationFrame;